import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { lineData } from "../@core/data/chart-data/line-data";
import { barData } from "../@core/data/chart-data/bar-data";
import { pieDataItem } from "../@core/data/chart-data/pie-data";
import { radarDataItem } from "../@core/data/chart-data/radar-data";
import { CurrentMonthConversations } from "../@core/data/chart-data/current-month-conversations";

import { BehaviorSubject, Subject, from } from "rxjs";
import { io } from "socket.io-client";
import { EnFrYearMonthsToDate } from "../@core/data/chart-data/en-fr-year-months-to-date";
import { IntentCountMonthToDate } from "../@core/data/chart-data/intent-count-month-to-date";
import { environment } from "../../environments/environment";
import { ScriptStore } from "../@core/data/chat-widget-scripts/scripts.store";

declare var document: any;

@Injectable({
  providedIn: "root",
})
export class ChatbotService {
  // Backend baseurl
  private baseUrl = environment.backendUrl;

  private botList: any[] = [];

  private selectedBot: any;
  private curBotID: string;
  private curBotIDSubject = new BehaviorSubject<string>("");
  curBotID$ = this.curBotIDSubject.asObservable();

  private processingBotChange: boolean;
  private processingBotChangeSubject = new BehaviorSubject<boolean>(false);
  processingBotChange$ = this.processingBotChangeSubject.asObservable();

  // Scripts
  private curScript: any = {};
  private script: HTMLScriptElement | null = null;

  constructor(private httpClient: HttpClient) {}

  init() {
    this.getAllBotIDs().subscribe({
      next: (res) => {
        // Iterate through map fields
        for (const bot in res) {
          if (
            res.hasOwnProperty(bot) &&
            res[bot].agent_id &&
            res[bot].agent_name &&
            res[bot].environment_id
          ) {
            this.botList.push({
              name: res[bot].agent_name,
              id: res[bot].agent_id,
              environmentID: res[bot].environment_id
            });
          }
        }
        this.selectedBot = sessionStorage.getItem("curBot");
        if (this.selectedBot == null) {
          this.selectedBot = this.botList[0];
        } else {
          this.selectedBot = JSON.parse(this.selectedBot);
        }
        this.setCurBotID(this.selectedBot);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  getBotList() {
    return this.botList;
  }

  getCurBot() {
    return this.selectedBot;
  }

  getAllBotIDs() {
    return this.httpClient.get(`${this.baseUrl}/bot/all`);
  }

  getCurBotID() {
    return this.curBotID;
  }

  // Switch cur bot
  setCurBotID(curBot: any) {
    // unload currently loaded bot
    this.unload();
    const botID = curBot.id;
    this.curBotID = botID;
    this.setCurScript();
    // Save cur botID in session storage
    sessionStorage.setItem("curBot", JSON.stringify(curBot));
    this.curBotIDSubject.next(botID);
  }

  setCurScript() {
    // Set curScript
    ScriptStore.forEach((script: any) => {
      if (script.agentID === this.curBotID) {
        this.curScript = {
          loaded: false,
          src: script.src,
          connectorWebhook: script.connectorWebhook,
          agentID: script.agentID,
          projectID: script.env,
          name: script.name
        };
      }
    });
  }

  // Processing flag
  setProcessingBotChange(processing: boolean) {
    this.processingBotChangeSubject.next(processing);
  }

  load() {
    return this.loadScript();
  }

  loadScript() {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.curScript.loaded) {
        resolve({ script: this.curScript.name, loaded: true, status: "Already Loaded" });
      } else {
        //load script
        this.script = document.createElement("script");
        this.script.src = this.curScript.src;
        this.script.id = "chatbot-script";
        this.script.async = true;
        this.script.setAttribute("projectId", this.curScript.projectID);
        this.script.setAttribute("agentId", this.curScript.agentID);
        this.script.setAttribute(
          "connectorWebhook",
          this.curScript.connectorWebhook
        );
        //Others
        this.script.onload = () => {
          this.curScript.loaded = true;
          resolve({ script: this.curScript.nameme, loaded: true, status: "Loaded" });
        };
        this.script.onerror = (error: any) =>
          resolve({ script: this.curScript.name, loaded: false, status: "Loaded" });
        document.getElementsByTagName("body")[0].appendChild(this.script);
      }
    });
  }

  unload() {
    this.unloadScript();
    this.cleanUpChatbot();

    const iframeElement = document.getElementById("chatBot");

    if (iframeElement) {
      iframeElement.parentNode.removeChild(iframeElement);
    }
  }

  unloadScript() {
    if (this.script) {
      this.script.remove();
      this.script = null;
      this.curScript.loaded = false;
    }
  }

  createIframe(containerElement = null) {
    if (typeof window["createIframe"] === "function") {
      if (containerElement) {
        window["createIframe"](containerElement);
      } else {
        window["createIframe"]();
      }
    }
  }

  cleanUpChatbot() {
    if (typeof window["cleanUpChatbot"] === "function") {
      window["cleanUpChatbot"]();
    }
  }

  restoreStartValues() {
    if (typeof window["restoreStartValues"] === "function") {
      console.log("Restoring Chat Widget");
      window["restoreStartValues"]();
    }
  }
}
