interface Scripts {
    name: string;
    env: string;
    agentID: string;
    environmentID?: string;
    locationID?: string;
    src: string;
    connectorWebhook: string;
}  
export const ScriptStore: Scripts[] = [
    {name: 'chatbot-script', env: 'platformdemo-374618', agentID: '', src: 'https://storage.googleapis.com/chat-widget-integration-script/integration_test/chatWidgetIntegration.js', connectorWebhook: "https://us-central1-platformdemo-374618.cloudfunctions.net/FAQGenerator_DFCX_Connector"},
    {name: 'chatbot-script', env: 'connex-telecommuncations-inc', agentID: '', src: 'https://storage.googleapis.com/chat-widget-integration-script-connex/chatWidgetIntegration.js', connectorWebhook: 'https://us-central1-connex-telecommunications-inc.cloudfunctions.net/ChatWidget_Connector'},
    {name: 'sales-demo', env: 'platformdemosales', agentID: 'da4e751d-7d7f-4e2f-a1f7-bdcf18439bef', src: 'https://storage.googleapis.com/chat_widget_integration-852967099252/va-hub/chatWidgetIntegration_sales-demo.js', connectorWebhook: "https://northamerica-northeast1-platformdemosales.cloudfunctions.net/ChatWidget_Connector"},
    {name: 'demo-brampton', env: 'platformdemosales', agentID: '21ad9c73-b57f-4cce-a8dc-28972588ff2b', src: 'https://storage.googleapis.com/chat_widget_integration-852967099252/va-hub/chatWidgetIntegration_demo-brampton.js', connectorWebhook: "https://northamerica-northeast1-platformdemosales.cloudfunctions.net/ChatWidget_Connector"},
    {name: 'alignment-health', env: 'platformdemosales', agentID: 'cae623d4-2d73-4215-a9d5-0d16f8d77f8c', src: 'https://storage.googleapis.com/chat_widget_integration-852967099252/va-hub/chatWidgetIntegration_alignment-health.js', connectorWebhook: "https://northamerica-northeast1-platformdemosales.cloudfunctions.net/ChatWidget_Connector"},
    {
        name: 'chateauguay', 
        env: 'platformdemosales', 
        agentID: '7c0110e8-b855-4c13-8389-1f09385e94db', 
        environmentID: 'f848e593-7319-4cd0-a1b0-bf6e8ff36277',
        locationID: 'global',
        src: 'https://storage.googleapis.com/chat_widget_integration-852967099252/va-hub/chateauguay/chatWidgetIntegration.js', 
        connectorWebhook: "https://northamerica-northeast2-platformdemosales.cloudfunctions.net/ChatWidget_Connector_CA"
    }
];